<template>
  <div>
    <!-- Filters -->
    <!--  <list-data-table-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    /> -->
    <!-- Table Container Card -->

    <b-card v-if="userIsAdmin==true">
      <b-row class="">
        <!-- Date -->
        <b-col cols="12" md="4">
          <b-form-group label="Date" label-for="date">
            <flat-pickr
                  v-model="taskData.date"
                  class="form-control"
                  :config="{ 
                    enableTime: false, 
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'd-m-Y',
                  }"
                  id="date" 
                  required
                />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="taskData.email" trim placeholder="Ex: investisseur@gmail.com,..."
              class="" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="sendGainsManualy"
              :readonly="taskData.date!=''"
            >

              <feather-icon icon="DollarSignIcon" size="16" />
              <span v-if="processing">
                Envoie en cours...
              </span>
              <span v-else>
                Envoyer gains manuellement
              </span>
              
            </b-button>
        </b-col>
      </b-row>

    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4"
            class="d-none d-sm-inline align-items-center justify-content-start mb-1 mb-md-0 pull-left">
            <label>Afficher</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>Elements par page</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end pull-right">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Rechercher..." />
              <!--  <b-button variant="primary" :to="{ name: 'gain-add-new' }">
                <span class="text-nowrap">Ajouter Portefeuille</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refDataListTable" class="position-relative" :items="fetchModuleGains" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="Aucun resultat trouvé"
        :sort-desc.sync="isSortDirDesc">
        <!-- Column: id -->
        <template #cell(id)="data">
          <span>
            GN0{{ data.item.id }}
          </span>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveDataStatusVariant(data.item.status)}`" class="text-capitalize">
            <span v-if="data.item.status == 0">
              <feather-icon icon="ReloadIcon" />
              En Attente
            </span>
            <span v-if="data.item.status == 1">
              <feather-icon icon="CheckIcon" />
              Confirmé
            </span>
            <span v-if="data.item.status == 4">
              <feather-icon icon="RemoveIcon" />
              Annulé
            </span>

          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data" v-if="userIsAdmin == true">
          <div class="text-nowrap">
            <feather-icon :id="`gain-row-${data.item.id}-preview-icon`" icon="EyeIcon" size="16" class="mx-1" @click="
              $router.push({
                name: 'gain-view',
                params: { id: data.item.id },
              })
              " />
            <b-tooltip title="Visionner Portefeuille" :target="`gain-row-${data.item.id}-preview-icon`" />

            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{
              name: 'gain-view',
              params: { id: data.item.id },
            }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Détails</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{
              name: 'gain-edit',
              params: { id: data.item.id },
            }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Modifier</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item
                @click="onDeleteQuestion(data.item)"
                @refetch-data="refetchData"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Supprimer</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.of }} Enregistements</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalData" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
import ListDataTableFilters from "./ListDataTableFilters.vue";
// sweet alert
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"; 

export default {
  components: {
    ListDataTableFilters,
    // ListDataTable,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    flatPickr
  },
  data() {
    return {
      userIsAdmin: JSON.parse(localStorage.getItem("userData")).role == "super-admin" ? true : false,
      processing: false,
      taskData: {},
    };
  },
  directives: {
    Ripple,
  },
  props: {},
  methods: {
    async sendGainsManualy() {
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "post";
      this.actionName = "Enregistrement...";

      // finalise form data
      formData = this.taskData;

      this.processing = true;

      await myApi({
        url: "send_gain_manualy",
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.processing = false;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 15000,
              showCancelButton: true,
              confirmButtonText: "Nouvel envoie?",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              this.processing = false;
              if (result.value) {
                // stay here
                // reinit form
                this.taskData = {};
              } else if (result.dismiss === "cancel") {
                // redirection to list
                // router.push({ name: "gain-list" });
              }
            });
          } else {
            this.processing = false;
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "BellIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          /////////////////////////////////////
          this.processing = false;
          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
    onDeleteQuestion(gain) {
      this.$swal({
        title: "Action Irreversible",
        text: "Voulez-vous vraiment supprimer ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Suppression]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onDelete(gain);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-gain";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const onDelete = (gain) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/deleteModule", gain.id)
        .then(() => {
          // show confirmation notification
          refetchData();
        });
    };

    const isAddNewDataSidebarActive = ref(false);

    const statusOptions = [
      { label: "En Activité", value: "active" },
      { label: "Inactif", value: "inactive" },
      { label: "Non-Spécifié", value: "pending" },
    ];

    const resolveDataStatusVariant = (status) => {
      if (status === 0) return "secondary";
      if (status === 1) return "success";
      if (status === 2) return "warning";
      return "secondary";
    };

    const {
      fetchModuleGains,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,

      // Extra Filters
      typeFilter,
      statusFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchModuleGains,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      onDelete,

      // Filter
      avatarText,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
